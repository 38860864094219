import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import { Link } from "gatsby"
import catImage from "../images/category/102.jpg"
import catNum1Image from "../images/category/72.jpg"
import catNum2Image from "../images/category/61.jpg"
import catNum3Image from "../images/category/48.jpg"
import catNum4Image from "../images/category/62.jpg"
import catNum5Image from "../images/category/59.jpg"
import catNum6Image from "../images/category/64.jpg"
import catNum7Image from "../images/category/60.jpg"
import catNum8Image from "../images/category/63.jpg"
import catNum9Image from "../images/category/43.jpg"
import LazyLoad from 'react-lazyload';

const ThingsToThrow = () => {
  //   const CatImage = "../images/category/101.jpg"
  const CatName = "Things To Throw"

  const CatTagLine = "Games, Toys and More!"

  const CatDesc =
    "Have a Ball! Or a Boomerang, it is your choice! With these great things to throw you will never be bored again! Check out all the cool sports, games, toys and more."

  const categories = [
    {
      catNum: 9,
      categoryName: "Things To Throw",
      categoryTag: "Games. Toys and More",
      categoryImage: catNum9Image,
      categoryLink: "/things-to-throw",
    },
    {
      catNum: 1,
      categoryName: "Boomerangs",
      categoryTag: "Get Some and You'll Be Coming Back For More",
      categoryImage: catNum1Image,
      categoryLink: "/boomerangs",
    },
    {
      catNum: 2,
      categoryName: "Throw Some Dice",
      categoryTag: "Feeling Lucky?",
      categoryImage: catNum2Image,
      categoryLink: "/throw-some-dice",
    },
    {
      catNum: 3,
      categoryName: "ThrowThings Accessories",
      categoryTag: "Associated Throw Things",
      categoryImage: catNum3Image,
      categoryLink: "/throwthings-accessories",
    },
    {
      catNum: 4,
      categoryName: "Throw A Punch",
      categoryTag: "Boxing Gloves, Bags & More",
      categoryImage: catNum4Image,
      categoryLink: "/throw-a-punch",
    },
    {
      catNum: 5,
      categoryName: "Bocce Sets",
      categoryTag: "They Are Rolling Out Of Here",
      categoryImage: catNum5Image,
      categoryLink: "/bocce-sets",
    },
    {
      catNum: 6,
      categoryName: "Darts & Dart Boards",
      categoryTag: "Take Aim Here",
      categoryImage: catNum6Image,
      categoryLink: "/darts-dart-boards",
    },
    {
      catNum: 7,
      categoryName: "Baseballs, Softballs & T-Balls",
      categoryTag: "Our Baseballs Are Always A Hit!",
      categoryImage: catNum7Image,
      categoryLink: "/baseballs-softballs-t-balls",
    },
    {
      catNum: 8,
      categoryName: "Footballs",
      categoryTag: "You'll Get A Kick Out Of These Footballs!",
      categoryImage: catNum8Image,
      categoryLink: "/footballs",
    },
  ]
  return (
    <Layout>
      <Head
        title="Things To Throw Department"
        description="From mini balls to foam bricks to hackey sacks,  if you want to throw it, chances are you can find it right here Check out all these great products!"
      />
      <Container>
        {/* <!-- section start --> */}
        <section className="section-b-space ratio_asos">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top-banner-wrapper">
                          {/* <Link to="#"> */}
                          <img
                            src={catImage}
                            className="img-fluid lazyload"
                            alt={CatTagLine}
                          />
                          {/* </Link> */}
                          {/* <Link to="#"><img src={require("../assets/images/mega-menu/2.jpg")} className="img-fluid blur-up lazyload" alt=""/></Link> */}
                          <div className="top-banner-content small-section">
                            <h4>{CatName}</h4>
                            <h5>{CatTagLine}</h5>
                            <p>{CatDesc}</p>
                          </div>
                        </div>
                        <div className="collection-product-wrapper">
                          <div className="product-wrapper-grid">
                            <div className="row">
                              {categories.map(category => (
                                <div
                                  key={category.catNum}
                                  className="col-xl-3 col-md-6 col-grid-box"
                                >
                                  <div className="product-box">
                                    <div className="img-wrapper">
                                      <div className="front">
                                        <Link to={category.categoryLink}>
                                          <LazyLoad>
                                          <img
                                            src={category.categoryImage}
                                            className="img-fluid lazyload bg-img"
                                            alt={category.categoryName}
                                          />
                                          </LazyLoad>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-detail">
                                      <div>
                                        <Link to={category.categoryLink}>
                                          <h4>{category.categoryName}</h4>
                                          <h6>{category.categoryTag}</h6>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section End --> */}
      </Container>
    </Layout>
  )
}

export default ThingsToThrow
